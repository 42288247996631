export enum DepartmentType{
  Minister = "Minister",
  Pastor   = "Pastor",
  Choir    = "Choir",
  Usher    = "Usher",
  Angels   = "Angel",
  Welfare  = "Welfare",
  Evangelism = "Evangelism",
  Children = "Children Department",
  Teenager = "Teenagers",
  Youth    = "Youth",


}
