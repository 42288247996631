export enum TestimonyType{
  Healing = "Healing",
  MiraculousDelivery = "Miraculous Delivery",
  NewJob = "New Job",
  Marriage = "Marriage",
  NewChild = "NewChild",
  Deliverance = "Deliverance",
  SpiritualAttacks = "Spiritual Attacks",
  Accidents = "Domestic/Road Accidents",
  Travelling = "Travelling",
  Promotion = "Promotion",

}
