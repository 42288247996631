import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: []
  })

  export class ProgramsComponent implements OnInit{

    constructor(){}

    ngOnInit(){}

  }
