import { Component, OnInit } from "@angular/core";


@Component({
  selector: 'app-convention',
  templateUrl:'./convention.component.html',
  styleUrls: ['./convention.component.css']
})

export class ConventionComponent implements OnInit{
  constructor(){

  }

  ngOnInit(){}
}

