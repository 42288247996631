import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-mountain-experience',
  templateUrl: './mountain-experience.component.html',
  styleUrls: ['./mountain-experience.component.css']
  })

  export class MountainExperienceComponent implements OnInit{

    constructor(){}

    ngOnInit(){}

  }