import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-women-convention',
  templateUrl: './women-convention.component.html',
  styleUrls: ['./women-convention.component.css']
})
export class WomenConventionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
