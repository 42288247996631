import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-couple-dinner',
  templateUrl: './couple-dinner.component.html',
  styleUrls: ['./couple-dinner.component.css']
})
export class CoupleDinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
