import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-praise-night',
  templateUrl: './praise-night.component.html',
  styleUrls: ['./praise-night.component.css']
})
export class PraiseNightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
